// src/Components/Thumbnail.js
import React from 'react';
import './Thumbnail.css';
import { Link } from 'react-router-dom';


export default function Thumbnail({ title, description, imageSource, gameSource }) {
  return (
    <div className='thumbnail-container'>
      <div className='title-container'>
        <span>{title}</span>
      </div>
      <div className='image-container'>
        <img src={imageSource} alt={title} />
      </div>
      <div className='description-container'>
        <span>{description}</span>
      </div>
      <div className='button-container'>
      <Link to={gameSource}>Play Game</Link>
      </div>
    </div>
  );
}