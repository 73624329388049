export const gameData = [
    { 
        title: 'Planetary Defense',
        description: 'Blast aliens trying to destroy your homeworld!',
        source: '/Games/PlanetaryDefense/index.html',
        imageSource: '/Games/PlanetaryDefense/planetarydefense.png',
        route: '/games/planetary-defense'
    },
    {
        title: 'Alien Attack',
        description: 'Another alien attack game.',
        source: '/Games/AlienAttack/index.html',
        imageSource: '/Games/AlienAttack/image.png',
        route: '/games/alien-attack'
    },
    {
        title: 'Speedy Saucer',
        description: "A flying saucer... one level and you can't win",
        source: '/Games/SpeedySaucer/index.html',
        imageSource: '/Games/SpeedySaucer/image.png',
        route: '/games/speedy-saucer'
    }
  ];
