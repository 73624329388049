// src/Routing/routes.js
import React from 'react';
import HomeScreen from '../Pages/HomeScreen/HomeScreen';
import GameScreen from '../Pages/GameScreen/GameScreen';
import { gameData } from '../Utilities/CommonUtilities'; // Import gameData

const dynamicRoutes = gameData.map((game) => ({
  path: `/games/${game.title.replace(/\s+/g, '-').toLowerCase()}`, // Example path format: /games/planetary-defense
  element: <GameScreen title={game.title} description={game.description} source={game.source} />,
  name: game.title,
  showInNavBar: false
}));

const routes = [
  { path: '/', element: <HomeScreen />, name: 'Home', showInNavBar: true},
  ...dynamicRoutes, // Spread the dynamically created routes
];

export default routes;