import React from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import routes from "../../Routing/routes";

export default function NavBar() {
  return (
    <div className="header-container">
      <div className="header-top">
      <div className="header-logo"></div>
      <div className="header-title-text">
        <span>Common Cadet Games</span>
      </div>
      </div>
      <div className="header-navigation-items">
        <ul>
          {routes.map((route) => (
            route.showInNavBar ? (
            <li key={route.path}>
              <Link to={route.path}>{route.name}</Link>
            </li> ) : <div></div>
          ))}
        </ul>
      </div>
    </div>
  );
}
