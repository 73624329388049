import React from 'react'
import GamePlayer from '../../Components/GamePlayer/GamePlayer'
import './GameScreen.css'

export default function GameScreen({title, description, source}) {
  return (
    <div className='game-screen-container'>
      <div className='game-title'>
        <span>{title}</span>
      </div>
      <GamePlayer 
      title={title}
      source={source}
      />
      <div className='game-description'>
        <span>Description: {description}</span>
      </div>
    </div>
  )
}
