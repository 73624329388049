// src/components/GamePlayer.js
import React from 'react';
import './GamePlayer.css';

export default function GamePlayer({ title, source }) {
  const handleFullscreen = () => {
    const iframe = document.getElementById('game-iframe');
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) { // Firefox
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) { // IE/Edge
      iframe.msRequestFullscreen();
    }
  };
  return (
    <div className="game-player-container">
      <iframe
        title={title}
        src={source}
        className='game-container'
        id='game-iframe'
      ></iframe>
      <button onClick={handleFullscreen} className="fullscreen-button">
        Fullscreen
      </button>
      </div>
  );
}