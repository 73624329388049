// src/HomeScreen/HomeScreen.js
import React from 'react';
import Thumbnail from '../../Components/Thumbnail/Thumbnail';
import { gameData } from '../../Utilities/CommonUtilities'; // Import gameData
import './HomeScreen.css'

export default function HomeScreen() {
  return (
    <div className='home-screen-container'>
      <div className='thumbnail-grid'>
        {gameData.map((game, index) => (
          <Thumbnail
            key={index}
            title={game.title}
            description={game.description}
            imageSource={game.imageSource}
            gameSource={game.route}
          />
        ))}
      </div>
    </div>
  );
}